import api from '@core/lib/api';
import {
  MeasurementInfo,
  TestCategories,
  TestCategoriesRequest,
  TestMeasurementRequest,
  TestQuestionRequest,
  TestQuestions,
} from './types';

export async function getTestCategories(
  params: TestCategoriesRequest,
): Promise<TestCategories> {
  const { data } = await api({
    url: `api/meta/test/categories`,
    method: 'get',
    params,
  });
  return data;
}

export async function getTestQuestionList(
  params: TestQuestionRequest,
): Promise<TestQuestions> {
  const { data } = await api({
    url: 'api/meta/test/questions',
    method: 'get',
    params,
  });
  return data;
}

export async function getTestMeasurementList(
  params: TestMeasurementRequest,
): Promise<MeasurementInfo[]> {
  const paramsQueryString = params?.code
    ?.map((c) => `code=${c}`)
    .join('&')
    .concat(params?.type ? `&type=${params?.type}` : '');
  const { data } = await api({
    url: `api/meta/test/measurements?${paramsQueryString}`,
    method: 'get',
  });
  return data;
}
