import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiError } from '@core/types/common';
import { deleteTestRecord } from '../api';
import { DeleteTestRecordParams } from '@modules/patientTest';
import { TEST_KEY } from '../key';

export const useDeleteTestRecord = (
  options: UseMutationOptions<null, ApiError, DeleteTestRecordParams> = {},
): UseMutationResult<null, ApiError, DeleteTestRecordParams> => {
  const queryClient = useQueryClient();
  return useMutation<null, ApiError, DeleteTestRecordParams>({
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [TEST_KEY, 'detail'] });

      if (options?.onSuccess instanceof Function) {
        options.onSuccess(...args);
      }
    },
    mutationFn: deleteTestRecord,
  });
};
